import { Box, Button, Flex, Image, Text, Title } from '@mantine/core';
import { IconHome } from '@tabler/icons-react';
import Head from 'next/head';

export default function Custom404() {
    return (
        <>
            <Head>
                <title>dvmGateway Portal - 404</title>
                <meta property="og:title" content="dvmGateway Portal" key="title" />

                {/* TODO: dvmReach favicon for now, maybe change in the future */}
                <link rel="icon" type="image/x-icon" href="/favicon-reach.ico" />
            </Head>

            <main>
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    w="100vw"
                    h="100vh"
                    gap="md"
                >
                    {/* Logo */}
                    <Image src="/gateway-logo.png" alt="dvmGateway logo" w="12rem" />

                    {/* Header */}
                    <Title order={2} mt="xs">
                        Uh oh! Page not found.
                    </Title>

                    {/* Contact us if no account yet */}
                    <Box style={{ textAlign: 'center' }}>
                        <Text>Sorry about that.</Text>

                        <Text>If you came here from a bookmark, it may be outdated.</Text>

                        <Button
                            mt="lg"
                            color="black"
                            leftSection={<IconHome size="1rem" />}
                            component="a"
                            href="/"
                        >
                            Take me to dvmGateway
                        </Button>
                    </Box>
                </Flex>
            </main>
        </>
    );
}
